<template>
  <div class="container">
    <br>
    <div class="row">
      <div class="col-md-7">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('footer.contact_us') }}</span>
          </div>
          <el-form
            ref="contact_form"
            :model="contact_form"
            :rules="contact_form_rules"
            label-width="130px"
          >
            <el-form-item :label="$t('footer.name')" prop="name">
              <el-input v-model="contact_form.name" />
            </el-form-item>
            <el-form-item :label="$t('footer.company')" prop="company">
              <el-input v-model="contact_form.company" />
            </el-form-item>
            <el-form-item :label="$t('footer.mobile')" prop="contactNo">
              <el-input v-model="contact_form.contactNo" />
            </el-form-item>
            <el-form-item :label="$t('footer.email')" prop="email">
              <el-input v-model="contact_form.email" />
            </el-form-item>
            <el-form-item :label="$t('footer.message')" prop="content">
              <el-input v-model="contact_form.content" type="textarea" />
            </el-form-item>
            <el-form-item>
              <input type="button" :value="$t('footer.send')" class="btn btn-default" @click="submitContact">
            </el-form-item>
          </el-form>
        </el-card>
      </div>
      <div class="col-md-5">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('body.contact') }}</span>
          </div>
          <ul class="list-icons">
            <li><i class="fa fa-phone pr-10" /> {{ $t('body.business_consulting') }}: {{ contact_no }}</li>
            <li><i class="fa fa-phone pr-10" /> {{ $t('body.sample_query') }}: {{ sample_query_no }}</li>
            <li><i class="fa fa-envelope-o pr-10" /> {{ $t('body.email') }}: <a :href="'mailto:'+email">{{ email }}</a></li>
            <li><i class="fa fa-map-marker pr-10" /> {{ $t('footer.address') }}</li>
          </ul>
        </el-card>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
import { submitContact } from '@/api/csp'
export default {
  name: 'Contact',
  data() {
    return {
      email: 'info@csptech.net',
      contact_no: '010-88820266',
      sample_query_no: '010-88820266',
      contact_form: {
        name: '',
        contactNo: '',
        email: '',
        company: '',
        content: ''
      }
    }
  },
  computed: {
    contact_form_rules() {
      return {
        name: [
          { required: true, message: this.$t('body.contact_form.rule_required_name'), trigger: 'blur' }
        ],
        contactNo: [
          { required: true, message: this.$t('body.contact_form.rule_required_contactNo'), trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.$t('body.contact_form.rule_required_email'), trigger: 'blur' }
        ],
        company: [
          { required: true, message: this.$t('body.contact_form.rule_required_company'), trigger: 'blur' }
        ],
        content: [
          { required: true, message: this.$t('body.contact_form.rule_required_content'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitContact() {
      this.$refs.contact_form.validate((valid) => {
        if (valid) {
          submitContact(this.contact_form).then(resp => {
            if (resp.status) {
              this.$alert('提交成功!')
              this.contact_form = {
                name: '',
                contactNo: '',
                email: '',
                company: '',
                content: ''
              }
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
